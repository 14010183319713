<template>
  <div>
    <!-- Modal  start-->
    <CModal :show.sync="isshow" :close-on-backdrop="false" title="Modal title 2" size="lg" color="primary">
      <template #header>
        <h6 class="modal-title">Reject Comment</h6>
        <CButtonClose @click="ChangeModal()" id="btnCloseRejectModal" class="text-white" />
      </template>

      <div>
        <CRow>
          <CCol>
            <CTextarea rows="3" label="Reason Reject" horizontal v-model="rejectModel.RejectComment" id="txtRejectComment" />
          </CCol>
        </CRow>
      </div>

      <template #footer>
        <CButton @click="SaveData()" id="btnRejectOK" color="primary">OK</CButton>
        <CButton @click="ChangeModal()" id="btnRejectCancel" color="secondary">Cancel</CButton>
      </template>
    </CModal>
    <!-- Modal  end-->
  </div>
</template>

<script>

import store from '../store';
import axios from "axios";
var URL_InsertReject = store.getters.URL + "/api/IdeationRejectComment/insert";

export default {
  name: "RejectModal",
  data() {
      return {
        k2Obj:[],
        // userProfile: JSON.parse(localStorage.getItem("userprofile")),
        // userToken: JSON.parse(localStorage.getItem("IdeationToken")),
        userProfile: JSON.parse(localStorage.getItem("userprofile")),
        userToken: JSON.parse(localStorage.getItem("IdeationToken")),
      }
  },
  props: {
    showModal: Boolean,
    rejectModel: {
      type: Object,
      default: function () {
        return {
          DocumentNo: "",
          ProcessID: "",
          RejectLevel: "",
          RejectUser: "",
          RejectComment: "",
        };
      }
    },
  },
  computed: {
    isshow: {
      get() {
        return this.showModal;
      },
      set(v) {
        this.$emit("RejectModal-Updated", v);
      }
    }
  },
  methods: {
    SaveData() {
      axios.put(URL_InsertReject, this.rejectModel)
            .then(response => {
              if (response.data.StatusCode === 200) {
                this.$_toast_Noti("success", "success", "Save reject comment success");
                this.ChangeModal();
                //this.isshow = false;
                //console.log("Data reject", response)

                //====> K2
                // this.k2Obj.push({
                //   Username: this.userProfile.Username,
                //   Token: this.userToken.token,
                //   ProcessID: this.rejectModel.ProcessID,
                //   Action: "Reject"
                // })
                // this.ConfirmK2Service(this.k2Obj)      
                //====> K2
                
                //this.ChangeModal();
                this.$emit("close-modal");
              }
              else {
                this.$_toast_Noti('error','error', response.data.Message);
              }
            })
            .catch(error => {
              this.$_toast_Noti('error','error', error);
            });
    },
    ChangeModal() {
      this.isshow = !this.isshow;
    },
    ConfirmK2Service(k2Obj){
      axios.put(store.getters.URL + '/api/IdeationRejectComment/k2reject',k2Obj).then(res => {
        if(res.data.StatusCode === 200){
          this.$_toast_Noti("success", "success", "Reject success");
          this.ChangeModal();
          this.$emit("close-modal");
        }
        else{
          this.$_toast_Noti('error','error', res.data.Message);
          this.ChangeModal();
        }
      }).catch(err => {
        this.$_toast_Noti('error','error', err);
        this.ChangeModal();
      })
    }
  }
};
</script>
